.layout-wrapper {
    display: grid;
    min-height: 100vh;
    width: 100%;
    grid-template-areas: "header" "main" "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.layout-wrapper .layout-header {
    grid-area: header;
}

.layout-wrapper .layout-main {
    grid-area: main;
}

.layout-wrapper .layout-footer {
    grid-area: footer;
}