h1 {
    text-transform: uppercase;
}

p, li, table {
    text-align: justify;
    color: black;
    font-weight: 500;
    font-size: 1rem;
}

.first-level > li, h1 {
    color: #fe6217;
    font-size: 1.2rem;
    font-weight: 700;
}

section > ol > li > ol > li, h2 {
    font-size: 1rem;
    font-weight: 700;
    color: black;
}

h3 {
    font-size: 0.8rem;
    font-weight: 700;
    color: black;
}

h3~p {
    font-size: 0.8rem;
    color: black;
}

#por-quanto-tempo-tratamos-os-seus-dados-pessoais li {
    font-weight: 500;
}

table {
    width: 100%;
}

table > thead {
    text-align: left;
}

table th:first-child {
   width: 10%
}

#lista-de-cookies table {
    width: 100%;
    background-color: #f5f5f5;
    border-collapse: collapse;
    font-size: 0.8rem;
}

#lista-de-cookies table th:first-child {
    width: 30%
}
